import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed left-0 top-0 z-50 w-full p-4 transition-colors duration-300 ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      <div className="container flex items-center justify-between">
        <span className="text-2xl font-bold leading-[38.40px] text-[#414450]">
          MaktabHub
        </span>
        <div className="flex items-center gap-10">
          <NavLink
            className="text-base font-bold leading-relaxed text-[#414450]"
            to={"/"}
          >
            Bosh Sahifa
          </NavLink>
          <NavLink
            className="text-base font-bold leading-relaxed text-[#414450]"
            to={"/"}
          >
            Maktablar
          </NavLink>
          <NavLink
            className="text-base font-bold leading-relaxed text-[#414450]"
            to={"/"}
          >
            Biz Haqimizda
          </NavLink>
          <NavLink
            className="text-base font-bold leading-relaxed text-[#414450]"
            to={"/"}
          >
            Blog
          </NavLink>
          <NavLink
            className="text-base font-bold leading-relaxed text-[#414450]"
            to={"/"}
          >
            Aloqa
          </NavLink>
        </div>
        <button className="flex flex-col items-center justify-start gap-2.5 rounded-full bg-[#1089ff] px-10 transition hover:from-[#3282b8] hover:to-[#0f4c75]">
          <span className="text-center text-base font-bold leading-[52px] tracking-wide text-white">
            Bog'lanish
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;

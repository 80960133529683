import schoolImg from "../img/school.jpg";

export const schools = [
  {
    name: "Nodirbek School",
    image: schoolImg,
    description: "Toshkentdagi eng yaxshi xususiy maktablardan biri.",
    rating: 4.9,
  },
  {
    name: "Sharof Rashidov School",
    image: schoolImg,
    description: "Xalqaro ta'lim bilan ta'minlaydigan maktab.",
    rating: 4.8,
  },
  {
    name: "Amir Temur Private School",
    image: schoolImg,
    description: "Yuqori sifatli ta'lim va ilg'or texnologiyalar bilan.",
    rating: 4.7,
  },
];

import React from "react";
import { schools } from "../../utils/schools-db";
import SchoolCard from "../../components/cards/SchoolCard";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const Schools = () => {
  return (
    <>
      <Header />

      <div className="container mx-auto mt-10 px-6 py-12">
        <h1 className="mb-6 text-center text-3xl font-semibold text-gray-800">
          Maktablar
        </h1>
        {/* Display list of schools */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {schools.map((school, index) => (
            <SchoolCard school={school} key={index} />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Schools;

import React from "react";
import Header from "../../components/header/Header";
import Hero from "../../components/homepage/hero/Hero";
import About from "../../components/homepage/about/About";
import TopSchools from "../../components/homepage/topSchools/TopSchools";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <TopSchools />
    </>
  );
};

export default Home;

import React from "react";

import { Link } from "react-router-dom";
import TopSchoolsCard from "./TopSchoolsCard";

import HumoSch from "../../../img/humo.png";
import PdpSch from "../../../img/pdp.png";
import CambridgeSch from "../../../img/cambridge.png";

const TopSchools = () => {
  return (
    <section className="my-[180px]">
      <div className="container flex flex-col gap-10">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
              maktablar
            </span>
            <span className="text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
              Top Maktablar
            </span>
          </div>
          <Link
            to={"/"}
            className="flex flex-col items-center justify-start gap-2.5 rounded-full border border-[#1089ff] px-10"
          >
            <span className="text-center text-base font-bold leading-[52px] tracking-wide text-[#1089ff]">
              Maktablarni ko'rish
            </span>
          </Link>
        </div>

        <div className="flex justify-between gap-4">
          <div className="flex flex-1 flex-col">
            <TopSchoolsCard
              image={HumoSch}
              title="HUMO SCHOOL ACADEMY"
              info="Bolalar bog'chasi va o'rta maktabni birlashtirgan zamonaviy ta'lim..."
            />
            <TopSchoolsCard
              image={PdpSch}
              title="PDP SCHOOL"
              info="Xususiy IT maktabi. Kelajak avlodlar uchun ishonchli va istiqbolli ta'lim..."
            />
            <TopSchoolsCard
              image={CambridgeSch}
              title="CAMBRIDGE SCHOOL OF IT AND ECONOMICS"
              info="3 tilli xususiy maktab..."
            />
          </div>
          <div className="flex flex-1 flex-col">
            <TopSchoolsCard
              image={HumoSch}
              title="HUMO SCHOOL ACADEMY"
              info="Bolalar bog'chasi va o'rta maktabni birlashtirgan zamonaviy ta'lim..."
            />
            <TopSchoolsCard
              image={PdpSch}
              title="PDP SCHOOL"
              info="Xususiy IT maktabi. Kelajak avlodlar uchun ishonchli va istiqbolli ta'lim..."
            />
            <TopSchoolsCard
              image={CambridgeSch}
              title="CAMBRIDGE SCHOOL OF IT AND ECONOMICS"
              info="3 tilli xususiy maktab..."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSchools;

// Footer.js
import React from "react";
import { FaFacebook, FaInstagram, FaTelegram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-8 text-white">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
          {/* Logo and Description */}
          <div>
            <h2 className="mb-4 text-2xl font-bold">TalimHub</h2>
            <p className="text-gray-400">
              O'zbekistondagi eng to'liq xususiy maktablar ma'lumotlar bazasi.
              Bizning platformamiz orqali maktablarni topish, taqqoslash va
              sharhlash mumkin.
            </p>
          </div>

          {/* Important Links */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Foydali Havolalar</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-400 hover:underline">
                  Bosh Sahifa
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-400 hover:underline">
                  Biz Haqimizda
                </a>
              </li>
              <li>
                <a href="/schools" className="text-gray-400 hover:underline">
                  Maktablar
                </a>
              </li>
              <li>
                <a href="/contact" className="text-gray-400 hover:underline">
                  Aloqa
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Ijtimoiy Tarmoqlar</h3>
            <div className="flex space-x-4">
              <a
                href="https://facebook.com"
                className="text-gray-400 hover:text-white"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="https://instagram.com"
                className="text-gray-400 hover:text-white"
              >
                <FaInstagram size={24} />
              </a>
              <a
                href="https://twitter.com"
                className="text-gray-400 hover:text-white"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://telegram.org"
                className="text-gray-400 hover:text-white"
              >
                <FaTelegram size={24} />
              </a>
            </div>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Aloqa</h3>
            <p className="text-gray-400">Email: info@talimhub.uz</p>
            <p className="text-gray-400">Telefon: +998 90 123-45-67</p>
            <p className="text-gray-400">Manzil: Toshkent, O'zbekiston</p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-gray-500">
          <p>&copy; 2024 TalimHub. Barcha huquqlar himoyalangan.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

const TopSchoolsCard = ({ image, title, info }) => {
  return (
    <div className="flex items-center justify-between rounded-lg shadow-lg">
      <div className="flex h-[200px] w-[200px] shrink-0 items-center justify-center p-5">
        <img
          className="w-full rounded-lg object-contain"
          src={image}
          alt="School Logo"
        />
      </div>
      <div className="flex flex-col items-start gap-3 p-5">
        <span className="flex items-center justify-start gap-2.5 rounded bg-[#03cea4] px-2 py-px text-sm font-normal leading-[21px] text-white">
          Toshkent
        </span>
        <span className="text-xl font-bold leading-[30px] text-[#1e212c]">
          {title}
        </span>
        <div className="text-lg font-normal leading-[27px] text-[#787a80]">
          {info}
        </div>
      </div>
    </div>
  );
};

export default TopSchoolsCard;

import React from "react";

const SchoolCard = ({ school }) => {
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow-lg">
      <img
        src={school.image}
        alt={school.name}
        className="h-48 w-full object-cover"
      />
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-800">{school.name}</h3>
        <p className="mt-2 text-gray-600">{school.description}</p>
        <p className="mt-4 text-gray-800">
          Reyting: <span className="text-yellow-500">{school.rating}</span>/5
        </p>
      </div>
    </div>
  );
};

export default SchoolCard;
